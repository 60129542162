import React, { useEffect } from "react";
import './App.css';
import './global.js';
import ReactLoading from "react-loading";

function App() {

    const [disease,setDisease] = React.useState("");
    const [name,setTestName] = React.useState("");
    const [dates,setSelectedDates] = React.useState([]);

    // const [testdates,setTestDates] = React.useState([]);
    const [testnames,setTestNames] = React.useState([]);
    const [testfiles,setTestFiles] = React.useState([]);

    const [minallowper,setMinPer] = React.useState(50);
    const [targetper,setTargetPer] = React.useState(60);
    const [isLoading,setLoading] = React.useState(false);

    const [hold,sethold] = React.useState([]);

    useEffect(() => {
        setLoading(true)
        fetch(window.server + "getfiles.php").then(res => res.json()).then(result => {
            // setTestDates(result.dates);
            setTestNames(result.names);
            setTestFiles(result.files);
            // setDisease(result.dates[0]);
            // setTestName(result.names[0]);
            console.log(result);
        }).then(() => {
            setLoading(false);
        });

        // fetch(window.server + "read.php?Answers=1").then(res => res.json()).then(result => {
        //     sethold(result)
        //     setLoading(false)
        // })
    },[]);

    
    const goLive = () => {
        if(name !== "" && name !== null && name !== undefined && disease !== "" && disease !== null && disease !== undefined)
        {        
            fetch(window.server + "pushlive.php?name=" + name + "&date=" + disease).then(res => res.json()).then(result => {
                if(result.result)
                {
                    alert("Live Push Started")
                }else{
                    alert("Failed to star push.")
                }
            });
        }else{
            alert("Please make sure you selected a Model and Date")
        }
    }


    const selectDis = (e) => {
        let dis = e.target.value;
        setDisease(dis);
        readDisInfo(dis);
        // update the dates
    }

    const selectName = (e) => {
        let dis = e.target.value;
        setTestName(dis);
        correctTestDates(dis);
        // update the names
    }

    const correctTestDates = (valin) => {
        setSelectedDates(testfiles.filter(val => val.includes(valin)).map(val => val.substring(val.indexOf("-") + 1,val.length - (val.length - val.lastIndexOf("-")))))
        setDisease("");
        sethold([])
    }

    const readDisInfo = (disIn) => {
        setLoading(true);
        fetch(window.server + "read.php?Answers=./data/" + name + "-" + disIn + "-data.json").then(res => res.json()).then(result => {
            sethold(result)
        }).then(() => {
            setLoading(false);
        });
    }

    const setMinPerx = (e) => {
        let hold = e.target.value;
        setMinPer(hold);
    }

    const setTargetPerx = (e) => {
        let hold = e.target.value;
        setTargetPer(hold);
    }

    return (
        <>
            <div className="controlbar">
                <select onChange={selectDis} className="float" value={disease}>
                <option value="" disabled={true}>Select Date</option>
                {
                    dates.map(opt => {
                        return(<option key={Math.random(40000)} value={opt}>{opt}</option>)                        
                    })
                }
                </select>
                <select onChange={selectName} className="float" value={name}>
                <option value="" disabled={true}>Select Target Model</option>
                {
                    testnames.map(opt => {
                        return(<option key={Math.random(40000)} value={opt}>{opt}</option>)                        
                    })
                }
                </select>
                <label htmlFor="min" className="over">Minimum allowed</label>
                <input className="inputallowed" id="min" type='number' onChange={setMinPerx} defaultValue={minallowper} />
                <label htmlFor="target" className="perfect">Target</label>
                <input className="inputallowed" id="target" type='number' onChange={setTargetPerx} defaultValue={targetper} />
            </div>
            <div className="bigredbuttondiv">
                <button className="bigred" disabled={disease===""?true:false} onClick={goLive}>Go Live!</button>
            </div>
            {isLoading?
                <div className="loadingSpinner">
                    <ReactLoading type="spin" color="Black" />
                </div>:<table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>result0</th>
                        <th>result1</th>
                        <th>result2</th>
                        <th>result3</th>
                        <th>result4</th>
                        <th>result5</th>
                        <th>result6</th>
                        <th>result7</th>
                        <th>result8</th>
                        <th>result9</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        hold.sort(function(a, b) {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                           })
                           .map(obj => {
                            let one = Number(((obj.result0)/(obj.count)*100).toFixed(2));
                            let two = Number(((obj.result1 + obj.result0)/(obj.count)*100).toFixed(2));
                            let three = Number(((obj.result2 + obj.result1 + obj.result0)/(obj.count)*100).toFixed(2));
                            let four = Number(((obj.result3 + obj.result2 + obj.result1 + obj.result0)/(obj.count)*100).toFixed(2))
                            let five = Number(((obj.result4 + obj.result3 + obj.result2 + obj.result1 + obj.result0)/(obj.count)*100).toFixed(2))
                            let six = Number(((obj.result5 + obj.result4 + obj.result3 + obj.result2 + obj.result1 + obj.result0)/(obj.count)*100).toFixed(2))
                            let seven = Number(((obj.result6 + obj.result5 + obj.result4 + obj.result3 + obj.result2 + obj.result1 + obj.result0)/(obj.count)*100).toFixed(2))
                            let eight = Number(((obj.result7 + obj.result6 + obj.result5 + obj.result4 + obj.result3 + obj.result2 + obj.result1 + obj.result0)/(obj.count)*100).toFixed(2))
                            let nine = Number(((obj.result8 + obj.result7 + obj.result6 + obj.result5 + obj.result4 + obj.result3 + obj.result2 + obj.result1 + obj.result0)/(obj.count)*100).toFixed(2))
                            let ten = Number(((obj.result9 + obj.result8 + obj.result7 + obj.result6 + obj.result5 + obj.result4 + obj.result3 + obj.result2 + obj.result1 + obj.result0)/(obj.count)*100).toFixed(2))
                            return(
                                <tr key={Math.random(40000)}>
                                    <td key={Math.random(40000)}>{obj.name}</td>
                                    <td key={Math.random(40000)} className={one>minallowper?(one>targetper?"perfect":"over"):"under"}>{one}</td>
                                    <td key={Math.random(40000)} className={two>minallowper?(two>targetper?"perfect":"over"):"under"}>{two}</td>
                                    <td key={Math.random(40000)} className={three>minallowper?(three>targetper?"perfect":"over"):"under"}>{three}</td>
                                    <td key={Math.random(40000)} className={four>minallowper?(four>targetper?"perfect":"over"):"under"} style={{borderLeft:"solid black"}}>{four}</td>
                                    <td key={Math.random(40000)} className={five>minallowper?(five>targetper?"perfect":"over"):"under"}>{five}</td>
                                    <td key={Math.random(40000)} className={six>minallowper?(six>targetper?"perfect":"over"):"under"}>{six}</td>
                                    <td key={Math.random(40000)} className={seven>minallowper?(seven>targetper?"perfect":"over"):"under"}>{seven}</td>
                                    <td key={Math.random(40000)} className={eight>minallowper?(eight>targetper?"perfect":"over"):"under"}>{eight}</td>
                                    <td key={Math.random(40000)} className={nine>minallowper?(nine>targetper?"perfect":"over"):"under"}>{nine}</td>
                                    <td key={Math.random(40000)} className={ten>minallowper?(ten>targetper?"perfect":"over"):"under"}>{ten}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                    <tfoot><tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr></tfoot>
                </table>}
        </>
    );
}

export default App;
